<template>
  <div>
    <h2>วอลเปเปอร์ทั้งหมด</h2>
    <b-alert variant="primary" :show="_isPartner">
      <div class="alert-body">
        <p>**ช่วงเวลาที่ 1 ของตรวจสอบรูป 08.00-11.00 แสดงขึ้น APP Ongphra 11.35-11.55</p>
        <p>***ช่วงเวลาที่ 2 ของตรวจสอบรูป 13.00-16.00 แสดงขึ้น APP Ongphra 16.35-16.55</p>
      </div>
    </b-alert>
    <br />
    <form @submit="onClickSearch">
      <b-form-group>
        <b-row align-v="center" no-gutters>
          <b-col :md="_isAdmin || _isMarketing ? 4 : 9" style="padding: 0.25rem;">
            <label class="">ค้นหา</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="ระบุชื่อวอลเปเปอร์"
              type="text"
              class="d-inline-block"
              :disabled="loading"
            />
          </b-col>

          <b-col v-if="_isAdmin || _isMarketing" md="5" style="padding: 0.25rem;">
            <label class="">ร้านค้า</label>

            <b-card no-body class="mb-0 w-100">
              <b-overlay
                :show="isFetchShopNameLoading || loading"
                spinner-small
                :opacity="$store.state.appConfig.layout.skin === 'dark' ? 0 : 0.12"
                variant="dark"
              >
                <v-select
                  v-model="searchByShops"
                  multiple
                  :get-option-label="op => `${op.shop_name} (${op.email})`"
                  :options="shopNameList"
                  placeholder="เลือก ร้านค้า (อีเมล)"
                />
                <template #overlay>
                  <div />
                </template>
              </b-overlay>
            </b-card>
          </b-col>

          <b-col md="2" style="padding: 0.25rem;">
            <label class="">สถานะรีวิว</label>

            <b-card class="mb-0 w-100" no-body>
              <v-select
                v-model="filterApproveStatus"
                :options="filterApproveStatusOptions"
                placeholder="เลือกสถานะ"
                :clearable="false"
              />
            </b-card>
          </b-col>

          <b-col align-self="end" md="1" style="padding: 0.25rem;">
            <b-btn type="submit" variant="primary" :disabled="loading" block>
              ค้นหา
            </b-btn>
          </b-col>
        </b-row>
      </b-form-group>
    </form>
    <b-overlay :show="loading" opacity="0">
      <div class="wallpapers-container">
        <b-row cols="1" cols-sm="2" cols-lg="4">
          <b-col v-for="(item, index) in items" :key="index">
            <WallpaperItem
              :item="item"
              @onClickSeeMore="onClickSeeMore"
              @onClickDelete="onClickDelete"
              @onClickEdit="onClickEdit"
            />
            <!-- <pre>{{ JSON.stringify(item, null, 2) }}</pre> -->
          </b-col>
        </b-row>
      </div>
    </b-overlay>

    <div class="wallpapers-bottom-nav">
      <b-row>
        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPage"
            :per-page="perPage"
            first-number
            align="center"
            last-number
            prev-class="prev-item"
            next-class="next-item"
            :disabled="loading"
            @input="onPageChange"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
          <!-- <b-pagination-nav v-model="currentPage" :number-of-pages="totalPage" base-url="#" first-number last-number /> -->
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import WallpaperItem from './components/WallpaperItem.vue'

export default {
  components: {
    WallpaperItem,
    vSelect,
  },
  data() {
    return {
      items: [],
      loading: false,
      searchTerm: '',
      currentPage: 1,
      perPage: 1,
      totalPage: 1,
      searchByShops: [],
      shopNameList: [],
      filterApproveStatusOptions: [
        { label: 'ทั้งหมด', value: '' },
        { label: 'กำลังตรวจสอบ', value: 'checking' },
        { label: 'อนุมัติ', value: 'approve' },
        { label: 'ไม่อนุมัติ', value: 'reject' },
      ],
      filterApproveStatus: { label: 'ทั้งหมด', value: '' },

      isFetchShopNameLoading: false,
    }
  },
  computed: {
    ...mapState('AuthStore', ['userInfo']),
    ...mapGetters('AuthStore', ['_isAdmin', '_isMarketing', '_isPartner']),
  },
  async created() {
    this.queryItems()
    this.fetchAllShop()
  },
  methods: {
    ...mapMutations('wallpapers', ['SET_DATA_EDIT_IMAGE']),
    async queryItems(page = 1, filterByName = '', shopList = [], filterApproveStatus = '') {
      const rootEle = document.documentElement
      rootEle.scrollTo({
        top: 0,
        behavior: 'smooth',
      })

      let url = 'api/partner/manage-images/fetch/index'

      // eslint-disable-next-line no-underscore-dangle
      if (this._isAdmin || this._isMarketing) {
        url = 'api/admin/admin-topic-images/v2/admin-topic-images'
      }

      this.items = []
      this.loading = true

      let resp

      // eslint-disable-next-line no-underscore-dangle
      if (this._isAdmin || this._isMarketing) {
        const request = {}
        if (shopList.length > 0) {
          request.shop_ids = shopList.map(v => v.id)
        }
        resp = await this.api
          .postV2(
            `${url}?page=${page}&filterType=image&filterByName=${filterByName}&filterStatus=${filterApproveStatus}`,
            request,
            this,
          )
          .catch(() => null)
      } else {
        resp = await this.api
          .getV2(
            `${url}?page=${page}&filterType=image&filterByName=${filterByName}&filterStatus=${filterApproveStatus}`,
          )
          .catch(() => null)
      }

      if (resp && resp?.code === 200) {
        this.perPage = resp?.data?.per_page
        this.totalPage = resp?.data?.total
        // this.currentPage = resp?.current_page
        this.items = [...resp?.data?.data]
      } else {
        this.gDisplayToast('ไม่สามารถเข้าถึงข้อมูลได้', 'โปรดติดต่อแอดมิน', 'danger')
      }
      this.loading = false
    },
    async fetchAllShop() {
      // eslint-disable-next-line no-underscore-dangle
      if (this._isAdmin || this._isMarketing) {
        this.isFetchShopNameLoading = true
        const resp = await this.api.getV2('api/admin/partners/fetch-all-shop-name').catch(() => null)
        this.isFetchShopNameLoading = false
        if (resp?.code === 200) {
          this.shopNameList = [...resp?.data]
        }
      }
    },
    onPageChange(value) {
      this.queryItems(value, this.searchTerm, this.searchByShops, this.filterApproveStatus.value)
    },
    onClickSearch(e) {
      e.preventDefault()

      if (this.currentPage > 1) {
        this.currentPage = 1
      } else {
        this.queryItems(1, this.searchTerm, this.searchByShops, this.filterApproveStatus.value)
      }
    },
    onClickSeeMore(item) {
      // console.log('onClickSeeMore', item)
      this.$router.push({ name: 'wallpapers-details', params: { id: item?.id || 0 } })
    },
    async onClickDelete(item) {
      let url = 'api/partner/manage-images/image-data/destroy'

      // eslint-disable-next-line camelcase, no-underscore-dangle
      if (this._isAdmin || this._isMarketing) {
        url = '/api/admin/admin-topic-images'
      }

      const resp = await this.api.deleteV2(`${url}/${item.id}`, this)
      // console.log('onClickDelete', resp)
      if (!resp) return

      if (resp.message) {
        this.gDisplayToast('พบข้อผิดพลาด', resp.message, 'danger')
        await this.queryItems()
        return
      }
      //   const newList = [...this.items].filter(v => v.id !== item.id)
      //   this.items = JSON.parse(JSON.stringify(newList))
      this.queryItems(1, this.searchTerm, this.searchByShops, this.filterApproveStatus.value)
    },
    async onClickEdit(item) {
      this.gOpenPageLoading()
      const resp = await this.api.getV2(`api/admin/admin-topic-images/${item.id}`).catch(() => null)
      this.gClosePageLoading()
      if (resp && resp?.code === 200) {
        this.SET_DATA_EDIT_IMAGE({ data: resp.data })
      } else {
        this.gDisplayToast('ไม่สามารถแก้ไขรูปได้', 'โปรดรีเฟรชหน้าจอ หรือติดต่อแอดมิน', 'danger')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.wallpapers-container {
  padding-bottom: 100px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}
.wallpapers-bottom-nav {
  //   background-color: red;
  position: fixed;
  right: 2vw;
  bottom: -1vh;
}
</style>
